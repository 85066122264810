import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    gameExpansionToggleDisplayStateSelector,
    gameWindowExpansionStateDisabled,
    gameWindowExpansionStateSelector
} from '../../selectors/game-window';
import { setGameWindowExpansionState } from '../../redux/actions/game-window';
import { types } from '../../redux/types';
import { postCustomerPreferences } from '../../redux/actions/customer-data/customer-data'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logo from '../../assets/icons/SWJ/swj-logo.png';
// import { swjTopTierLogoUrlSelector } from '../../selectors/site-wide-jackpot';

export const MinMaxGameToggle: React.FC = () => {
    require(`./styles/fanduel-casino/style.scss`);
    const dispatch = useDispatch();

    const isShowing = useSelector(gameExpansionToggleDisplayStateSelector);
    const isOn = useSelector(gameWindowExpansionStateSelector);
    const isSpinInProgress = useSelector(state => state?.['gameWindow']?.spinInProgress);
    const disabled = useSelector(gameWindowExpansionStateDisabled);
    const customerData = useSelector(state => state?.['customerData']);

    useEffect(() => {
        if (!customerData?.loading && !('showSWJElements' in customerData?.preferences)){
            dispatch({
                type: types.CUSTOMER_FEATURES_RECEIVED,
                payload: {...customerData, preferences:{ ...customerData?.preferences, showSWJElements: true}}
            });
            postCustomerPreferences({showSWJElements: true})
        }
        dispatch(setGameWindowExpansionState(customerData?.preferences?.showSWJElements))
    }, [customerData]);
    // // TODO use this icon once its updated in Prismic
    // const logo = useSelector(swjTopTierLogoUrlSelector);

    const handleClick = () => {
        if (!disabled && !isSpinInProgress) {
            postCustomerPreferences({showSWJElements: !isOn})
            dispatch(setGameWindowExpansionState(!isOn));
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (!disabled && !isSpinInProgress && (e.key === 'Enter' || e.key === ' ')) {
            handleClick();
        }
    };

    return (
        <div
            className={`min-max-toggle ${isOn ? 'on' : 'off'} ${(disabled || isSpinInProgress) && 'disabled'} ${(isShowing || (!isOn && !isShowing)) && 'show'}`}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            role="button"
            tabIndex={0}
            aria-pressed={isOn}
            aria-disabled={disabled || isSpinInProgress}
        >
            <div className="icon-container">
                <div className="not-show-icon" />
                <div className="icon off-icon">
                    <div className="cancel-icon" />
                    <div className="logo-icon" style={{ backgroundImage: `url(${logo})` }} />
                </div>
                <div className="icon on-icon">
                    <div className="logo-icon" style={{ backgroundImage: `url(${logo})` }} />
                </div>
                <div className="white-underlay" />
            </div>
            <div className="text-container">
                <div className="text-on">
                    Hide
                </div>
                <div className="text-off">
                    Show
                </div>
            </div>
        </div>
    );
};

